const configQueryMap = {
  'navbar-vertical-collapsed': 'mdsIsNavbarVerticalCollapsed',
  'color-scheme': 'mdsTheme',
  'navigation-type': 'mdsNavbarPosition',
  'vertical-navbar-appearance': 'mdsNavbarVerticalStyle',
  'horizontal-navbar-shape': 'mdsNavbarTopShape',
  'horizontal-navbar-appearance': 'mdsNavbarTopStyle'
}

const initialConfig = {
  mdsIsNavbarVerticalCollapsed: true,
  mdsTheme: 'light',
  mdsNavbarTopStyle: 'default',
  mdsNavbarVerticalStyle: 'default',
  mdsNavbarPosition: 'horizontal',
  mdsNavbarTopShape: 'default',
  mdsIsRTL: false,
  mdsSupportChat: false
}

const CONFIG = {...initialConfig}

const setConfig = (payload, persist = true) => {
  Object.keys(payload).forEach(key => {
    CONFIG[key] = payload[key]

    if (persist) {
      localStorage.setItem(key, payload[key])
    }
  })
}

const resetConfig = () => {
  Object.keys(initialConfig).forEach(key => {
    CONFIG[key] = initialConfig[key]
    localStorage.setItem(key, initialConfig[key])
  })
}

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())

if (Object.keys(params).length > 0 && Object.keys(params).includes('theme-control')) {
  resetConfig()

  Object.keys(params).forEach(param => {
    if (configQueryMap[param]) {
      // setConfig({
      //   [configQueryMap[param]]: params[param]
      // })
      localStorage.setItem(configQueryMap[param], params[param])
    }
  })
}

Object.keys(CONFIG).forEach(key => {
  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, CONFIG[key])
  } else {
    try {
      setConfig({
        [key]: JSON.parse(localStorage.getItem(key))
      })
    } catch {
      setConfig({
        [key]: localStorage.getItem(key)
      })
    }
  }
})

if (localStorage.getItem('mdsTheme') === 'dark') {
  document.documentElement.classList.add('dark')
}

if (!!JSON.parse(localStorage.getItem('mdsIsNavbarVerticalCollapsed'))) {
  document.documentElement.classList.add('navbar-vertical-collapsed')
}

if (localStorage.getItem('mdsNavbarPosition') === 'horizontal') {
  document.documentElement.classList.add('navbar-horizontal')
}

if (localStorage.getItem('mdsNavbarPosition') === 'combo') {
  document.documentElement.classList.add('navbar-combo')
}

export default {
  config: CONFIG,
  reset: resetConfig,
  set: setConfig
}
